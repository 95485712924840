<template>
    <Tier
        :topPadding="topPadding"
        :bottomPadding="bottomPadding"
        :background="colorScheme"
        :isFullBleed="isFullBleed"
        :class="CSSClasses"
        class="stream-tier"
    >
        <div class="columns">
            <template v-for="(width, index) in columnWidths">
                <template v-if="blocks[index]">
                    <template v-if="blocks[index].blockType === 'htmlBlock'">
                        <div :key="blocks[index]?.id || blocks[index]" :class="`column-width-${width}`">
                            <StreamBlockParser :block="blocks[index]" />
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="width === '25'">
                            <div :key="blocks[index]?.id || blocks[index]" class="column-width-25">
                                <StreamBlockParser class="size-25" :block="blocks[index]" size="25" />
                                <StreamBlockParser class="size-50" :block="blocks[index]" size="50" />
                            </div>
                        </template>
                        <template v-if="width === '33'">
                            <div :key="blocks[index]?.id || blocks[index]" class="column-width-33">
                                <StreamBlockParser class="size-33" :block="blocks[index]" size="33" />
                                <StreamBlockParser class="size-50" :block="blocks[index]" size="50" />
                            </div>
                        </template>
                        <template v-if="width === '50'">
                            <div :key="blocks[index]?.id || blocks[index]" class="column-width-50">
                                <StreamBlockParser :block="blocks[index]" size="50" />
                            </div>
                        </template>
                        <template v-if="width === '100'">
                            <div :key="blocks[index]?.id || blocks[index]" :class="`column-width-100`">
                                <StreamBlockParser class="size-100" :block="blocks[index]" size="100" />
                                <StreamBlockParser class="size-50" :block="blocks[index]" size="50" />
                            </div>
                        </template>
                        <template v-if="width === 'full'">
                            <div :key="blocks[index]?.id || blocks[index]" :class="`column-width-full`">
                                <StreamBlockParser class="size-full" :block="blocks[index]" size="full" />
                            </div>
                        </template>
                    </template>
                </template>
            </template>
        </div>
    </Tier>
</template>

<script setup>
import { provide } from 'vue';

const props = defineProps({
    colorScheme: {
        type: String,
        default: 'white',
        validator: (value) => {
            return ['white', 'grey', 'blue'].includes(value);
        },
    },
    columnLayout: {
        type: String,
        default: 'w100',
        validator: (value) => {
            return ['full_bleed', 'w100', 'w50_50', 'w50_25_25', 'w25_25_50', 'w33_33_33', 'w25_25_25_25'].includes(
                value,
            );
        },
    },
    topPadding: {
        type: String,
        default: 'standard',
        validator: (value) => {
            return ['standard', 'extra', 'negative'].includes(value);
        },
    },
    bottomPadding: {
        type: String,
        default: 'standard',
        validator: (value) => {
            return ['standard', 'extra', 'negative'].includes(value);
        },
    },
    alignment: {
        type: String,
        default: 'top',
        validator: (value) => {
            return ['top', 'middle', 'bottom'].includes(value);
        },
    },
    blocks: {
        type: Array,
        default: () => [],
    },
});

provide('colorContext', props.colorScheme);
provide('tierTheme', props.colorScheme === 'blue' ? 'dark' : 'light');
provide('tierSize', props.columnLayout);
provide('imageSource', 'stream');

const columnWidths = computed(() => {
    return props.columnLayout === 'full_bleed' ? ['full'] : props.columnLayout.replace('w', '').split('_') || [];
});

const isFullBleed = computed(() => {
    return props.columnLayout === 'full_bleed';
});
const CSSClasses = computed(() => {
    return [`alignment-${props.alignment}`];
});
</script>

<style lang="scss" scoped>
.stream-tier {
    &.full-bleed {
        .columns {
            padding: 0;
        }
    }
    &.alignment-middle {
        .columns {
            align-items: center;
        }
    }
    &.alignment-bottom {
        .columns {
            align-items: end;
        }
    }
}

.columns {
    @include grid;
}

.column-width {
    &-full {
        grid-column: 1 / -1;
    }

    &-100 {
        grid-column: 1 / -1;
        .size-100 {
            display: none;
            @include media-query(phone-mw) {
                display: block;
            }
        }
        .size-50 {
            @include media-query(phone-mw) {
                display: none;
            }
        }
    }

    &-50 {
        grid-column: 1 / -1;
        @include media-query(tablet-mw) {
            grid-column: span 6;
        }
        @include media-query(mobile) {
            margin-bottom: 2em;
        }
    }

    &-33 {
        grid-column: 1 / -1;
        @include media-query(tablet-mw) {
            grid-column: span 4;
        }
        @include media-query(mobile) {
            margin-bottom: 2em;
        }
        .size-33 {
            display: none;
            @include media-query(tablet-mw) {
                display: block;
            }
        }
        .size-50 {
            @include media-query(tablet-mw) {
                display: none;
            }
        }
    }

    &-25 {
        grid-column: 1 / -1;
        @include media-query(mobile) {
            margin-bottom: 2em;
        }
        @include media-query(phone-mw) {
            grid-column: span 6;
        }
        @include media-query(tablet-mw) {
            grid-column: span 3;
        }
        .size-25 {
            display: none;
            @include media-query(tablet-mw) {
                display: block;
            }
        }
        .size-50 {
            @include media-query(tablet-mw) {
                display: none;
            }
        }
    }
}
</style>
